<template>
  <div class="el-img-upload">
    <el-upload :action="uploadUrl" :headers="headers" :show-file-list="false" accept=".zip,.rar"
      :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" class="avatar-uploader"
      style="display: block;">
      <div v-if="FileUrl">
        <p v-if="isBigImg" class="avatar">{{ FileUrl }}</p>
        <p v-else class="avatar">{{ FileUrl }}</p>
      </div>
      <div v-else>
        <p>点击上传压缩文件</p>
      </div>
      <i v-if="FileUrl" class="el-icon-circle-close" @click.stop="handleDelete" />
      <div slot="tip" class="el-upload__tip">
        {{ placeTxt }}
      </div>
    </el-upload>
  </div>
</template>
  
<script>
import environments from '@/config/url'
import { encryptByDES } from '@/utils/3DES'
const uploadUrl = environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl
export default {
  name: 'GlobalUpload',
  props: {
    existFile: {
      type: String,
      default: ''
    },
    placeTxt: {
      type: String,
      default: ''
    },
    fileSize: {
      type: Number,
      default: 10
    },
    isBigImg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadUrl,
      FileUrl: '',
      headers: {
        'ACCESS_TOKEN': localStorage.getItem('token'),
        'channel': 'platformPc',
        'TOKEN': ''
      },
      showViewer: false,
      imgList: []
    }
  },
  watch: {
    existFile(val) {
      console.log(val);
      this.FileUrl = val
    }
  },
  created() {
    console.log(this.existFile);
    this.FileUrl = this.existFile
  },
  methods: {
    handleAvatarSuccess(res) {
      console.log(res)
      this.FileUrl = res.fileUrl
      this.$emit('handleChange', this.FileUrl)
    },
    beforeAvatarUpload(file) {
      console.log(file);
      this.headers.TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(), '63e42c2444e94c1ebca21d30d2aa39a5'), localStorage.getItem('token') || '')
      const isLt2M = file.size / 1024 / 1024 < this.fileSize
      if (!isLt2M) {
        this.$message.error(`上传文件大小不能超过 ${this.fileSize}MB!`)
      }
      return isLt2M
    },
    handleDelete() {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.FileUrl = ''
        this.$emit('handleDelete')
      })
    },
  }
}
</script>
  
<style scoped lang="scss">
@import "src/assets/scss/element/theme-color";

.el-img-upload {
  .el-upload__tip {
    color: $--color-danger;
  }

}

.avatar {
  width: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical
}
</style>
  
<style lang="scss" scoped>
.el-img-upload {
  .el-upload {
    width: 400px;
    height: 40px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-icon-plus {
      font-size: 30px;
      color: #999999;
    }

    .el-icon-circle-close {
      position: absolute;
      color: #999999;
      top: 2px;
      right: 2px;
      z-index: 100;
      font-size: 15px;
      opacity: 0.5;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
        color: #ef032a;
      }
    }
  }
}
</style>
  